import React from 'react'

const FormGroup = ({label,name, updateInput,type, value }) => {
    return (
        <div className="flex flex-col mt-4  font-customBody">
            <label className="font-medium mb-4">{label}</label>
            {(type === "email" || type ==="text") ? (
            <input className="border border-gray-800 p-2 focus:outline-none focus:shadow-outline active:bg-gray-200"  type={type} name={name} value={value} onChange={(e)=>updateInput(e.target.value)} />
            ) :(
                <textarea name={name} className="border border-gray-900 p-2 focus:outline-none focus:shadow-outline active:bg-gray-200" rows="7" onChange={(e)=>updateInput(e.target.value)}></textarea>
            )}
            
        </div>
    )
}

export default FormGroup
