import React, {useState, useEffect} from 'react'
import { navigateTo } from "gatsby-link";
import FormGroup from "./FormGroup"
import ReCAPTCHA from "react-google-recaptcha";

function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

const recaptchaRef = React.createRef();

const submitForm = (e,data) => { 
    e.preventDefault()
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();
    console.log(recaptchaValue);

    if (data['fullname'].trim() == "" || data['email'].trim() == "" || data['message'].trim() == "") {
        alert('Please fill in all the input fields.')
        return
    }
    fetch('/', {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          'g-recaptcha-response': recaptchaValue,
          ...data
        })
    }).then(() => navigateTo("/success"))
    .catch(error => alert(error));
 }


const Form = () => {

    const [fullname, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const data = {
        fullname,
        email,
        message,
    }

    return (
        <form name="contact" className="p-4 w-screen md:px-8 lg:w-full" method="POST" data-netlify="true" data-netlify-recaptcha="true" data-netlify-honeypot="bot-field" onSubmit={(e) => submitForm(e,data)}>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <h3 className="text-base font-customBody">Send An Enquiry</h3>
            <FormGroup label="Full Name" name="fullname" value={fullname} type="text" updateInput={setName}/>
            <FormGroup label="Email" name="email" type="email" value={email} updateInput={setEmail}/>
            <FormGroup label="Message" name="message"  value={message} updateInput={setMessage}/>
            
            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.SITE_RECAPTCHA_KEY} />
            <button className="my-4 p-4 font-medium bg-primary text-gray-100 w-1/2 md:w-1/3 float-right font-customHeader hover:bg-orange-500 focus:outline-none focus:shadow-outline active:bg-gray-900" type="submit">Submit</button>
        </form>
    )
}

export default Form
