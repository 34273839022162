import React from 'react'
import Layout from "../components/layout"
import GoogleMap from "../components/GoogleMap"
import Form from "../components/Form"


const contact = () => {

    return (
        <Layout>
            <div className="px-4 md:px-8 relative">
                <h1 className="font-customHeader">Contact Us</h1>
                <svg className="absolute right-0 z-0 h-100vh md:h-80vh top-0 hidden sm:hidden md:block lg:block xl:max-w-6xl  xl:h-screen md:-mt-10rem"  viewBox="0 0 439 870" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.82238 358.406C5.29368 247.996 31.482 117.204 124.738 0H439V869.685C376.264 872.234 193.552 860.829 117.024 778.254C21.3652 675.036 -5.26547 508.732 0.82238 358.406Z" fill="#0061F3"/>
                </svg>
            </div>

            <div className="lg:flex lg:items-top">
                <div className="lg:w-1/2 lg:pl-8 ">
                    <GoogleMap />
                    <div className="w-full m-auto p-4 z-20 relative bg-gray-800 text-gray-100 text-sm font-customBody md:px-8 lg:mt-0 ">
                    <p className="font-medium my-1">Our Address:</p>
                    <p className="my-1">211-213 The Boulevarde, Fairfield Heights, NSW, 2165</p>
                    </div>
                </div>
            
                <div className="lg:w-1/2 z-20 relative lg:mr-8 lg:ml-4 md:bg-gray-100 lg:pb-4 lg:shadow">
                    <div className="p-4  font-customBody md:px-8 lg:mt-4 lg:font-medium">
                        <p>Have an enquiry? Call us or simply tell us what it is by filling out the form below. We usually take 1-2 business days to reply back.</p>
                        <p><span className="font-medium">Telephone: </span>(02) 9724 9773</p>
                    </div>
                    <hr></hr>
                
                    <Form />
                </div>
           </div>
        </Layout>
    )
}

export default contact
