import React from 'react'
import GoogleMapReact from 'google-map-react'
import Placeholder from "../images/placeholder.svg"

const defaultProps = {
  center: {
    lat: -33.864047,
    lng: 150.938299,
  },
  zoom: 15,
}

const AnyReactComponent = ({ text }) => <div className="absolute left-0 flex  "><img src={Placeholder} className="w-8"/>{text}</div>

const GoogleMap = () => (
  <div className="h-30 w-full lg:h-50vh">
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyAU-v3CekyWf-ld73rPdKLjeaKWLbZFXoo' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent
        lat={-33.864047}
        lng={150.938299}
        text={''}
      />

    </GoogleMapReact>
  </div>
)

export default GoogleMap
